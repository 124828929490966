import { SEO } from 'components/blocks/seo';
import { Comparison } from 'components/pages/oss-vs-cloud/comparison';
import { Difference } from 'components/pages/oss-vs-cloud/difference';
import { Hero } from 'components/pages/oss-vs-cloud/hero';
import CaseStudies from 'components/shared/case-studies';
import { CTA } from 'components/shared/cta';
import { Partners } from 'components/shared/partners';
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const OssVsCloudPage = () => (
  <DefaultLayout>
    <Hero />
    <Difference />
    <CTA
      title={'Open Source Stack or SaaS?'}
      description={
        // eslint-disable-next-line max-len
        'Learn what to consider to build a scalable load testing solution'
      }
      buttonText={'Read more'}
      buttonRef={
        'https://grafana.com/blog/2024/01/30/load-testing-tools-open-source-considerations-for-homegrown-solutions/'
      }
      themeLight
    />
    <Comparison />
    <CaseStudies
      title="Related resources"
      page={null}
      cases={['collaborativeWhitePaper', 'whitePaper', 'k6CloudUnderTheHood2']}
    />

    <Partners
      title={
        // eslint-disable-next-line max-len
        'Trusted and loved by more than 6000 companies for performance testing, including:'
      }
    />
    <CTA
      title={'Ready to try Grafana Cloud k6?'}
      description={'Run 500 VU hours each month for free or let us help you get started'}
      mode="centered"
    />
  </DefaultLayout>
);

export const query = graphql`
  query {
    ogImage: file(absolutePath: { regex: "/oss-vs-cloud/oss-vs-cloud-og-image.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 95)
        resize(width: 1200, quality: 95) {
          src
        }
      }
    }
  }
`;

export default OssVsCloudPage;

export const Head = ({ data }) => {
  const pageMetaData = {
    data: {
      ...SEO_DATA['oss-vs-cloud'].data,
      image: data.ogImage,
    },
  };

  return <SEO {...pageMetaData} />;
};
