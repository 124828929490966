import { Heading } from 'components/shared/heading';
import { Lines } from 'components/shared/lines';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useLayoutEffect, useState } from 'react';

import styles from './comparison.module.scss';
import ArrowIcon from './svg/arrow.inline.svg';
import CheckIcon from './svg/check.inline.svg';
import CrossIcon from './svg/cross.inline.svg';

const iconsMap = {
  check: () => <CheckIcon />,
  cross: () => <CrossIcon />,
};

export const Comparison = () => {
  const [activeCategory, setActiveCategory] = useState('');
  const {
    allComparisonJson: { edges: TABLE_DATA },
  } = useStaticQuery(graphql`
    query comparisonData {
      allComparisonJson {
        edges {
          node {
            category
            items {
              title
              data {
                oss {
                  type
                  value
                }
                cloud {
                  value
                  type
                }
              }
            }
          }
        }
      }
    }
  `);

  useLayoutEffect(() => {
    if (!activeCategory) {
      setActiveCategory(TABLE_DATA[0].node.category);
    }
  }, [activeCategory, TABLE_DATA]);

  const handleCategoryClick = (category) => () => {
    setActiveCategory(category);
  };

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <Heading className={styles.title} tag={'h3'}>
          Feature comparison
        </Heading>

        <div className={'row justify-content-center'}>
          <div className={'col-12'}>
            <div className={styles.selectWrapper}>
              <select
                className={styles.select}
                value={activeCategory}
                onChange={(e) => setActiveCategory(e.target.value)}
              >
                {TABLE_DATA.map(({ node: { category } }) => (
                  <option value={category} key={category}>
                    {category.toUpperCase()}
                  </option>
                ))}
              </select>
              <ArrowIcon className={styles.selectIcon} />
            </div>
            <ul className={styles.categories}>
              {TABLE_DATA.map(({ node: { category } }) => (
                <li key={category}>
                  <button
                    className={category === activeCategory ? styles.active : undefined}
                    type="button"
                    onClick={handleCategoryClick(category)}
                  >
                    {category}
                  </button>
                </li>
              ))}
            </ul>
            <div className={styles.tableWrapper}>
              <div className={styles.tableWrapperInner}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th />
                      <th>k6 OSS</th>
                      <th>Grafana Cloud k6</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!activeCategory &&
                      TABLE_DATA.find(({ node: { category } }) => category === activeCategory).node.items.map(
                        ({ title, data }, i) => (
                          <tr key={`table-${i}`}>
                            <th>{title}</th>
                            {Object.keys(data).map((platform, j) => (
                              <td key={`platform-${j}`}>
                                {data[platform].type === 'text' && data[platform].value}
                                {data[platform].type === 'icon' && iconsMap[data[platform].value]()}
                                {data[platform].type === 'link' && (
                                  <a href={data[platform].value} className={'link'}>
                                    See more
                                  </a>
                                )}
                              </td>
                            ))}
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.selectWrapper}>
              <select
                className={styles.select}
                value={activeCategory}
                onChange={(e) => setActiveCategory(e.target.value)}
              >
                {TABLE_DATA.map(({ node: { category } }) => (
                  <option value={category} key={category}>
                    {category.toUpperCase()}
                  </option>
                ))}
              </select>
              <ArrowIcon className={styles.selectIcon} />
            </div>
            <ul className={styles.categories}>
              {TABLE_DATA.map(({ node: { category } }) => (
                <li key={category}>
                  <button
                    className={category === activeCategory ? styles.active : undefined}
                    type="button"
                    onClick={handleCategoryClick(category)}
                  >
                    {category}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Lines imageNumber={2} className={styles.linesWrapperRightTop} />
      <Lines imageNumber={3} className={styles.linesWrapperLeft} />
    </section>
  );
};
