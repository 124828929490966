import classNames from 'classnames';
import { PageInfo } from 'components/shared/page-info';
import React, { useCallback } from 'react';

import styles from './hero.module.scss';
import Bg from './svg/bg.inline.svg';

export const Hero = () => {
  const BgPattern = useCallback(({ className }) => <Bg className={classNames(className, styles.bgPattern)} />, []);

  return <PageInfo title="Comparing k6 OSS and Grafana Cloud k6" withBgPattern bgPattern={BgPattern} />;
};
