import classNames from 'classnames';
import { Lines } from 'components/shared/lines';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import styles from './difference.module.scss';
import ArrowFlat from './svg/arrow-flat.inline.svg';
import Arrow from './svg/arrow.inline.svg';
import Datadog from './svg/datadog.inline.svg';
import Grafana from './svg/grafana.inline.svg';
import InfluxDB from './svg/influxdb.inline.svg';
import Cloud from './svg/k6-cloud.inline.svg';
import Oss from './svg/k6-oss.inline.svg';
import Prometheus from './svg/prometheus.inline.svg';
import Relic from './svg/relic.inline.svg';
import Timescale from './svg/timescale.inline.svg';

const iconsMap = {
  timescale: Timescale,
  influxdb: InfluxDB,
  prometheus: Prometheus,
  relic: Relic,
  datadog: Datadog,
  grafana: Grafana,
};

const DiffCard = ({ node }) => {
  const { rowName, oss, cloud } = node;

  return (
    <li key={rowName} className={styles.row}>
      <div className={styles.label}>
        <span>{rowName}</span>
      </div>
      <div className={classNames(styles.block, styles.oss)}>
        <p>
          <span>{oss.value}</span>
          {oss.params?.brackets ? <b>{oss.params.brackets}</b> : null}
        </p>
        {oss.params?.icons?.length ? (
          <div>
            {oss.params.icons.map((icon, idx) => {
              const Icon = iconsMap[icon];
              return <Icon key={idx} />;
            })}
          </div>
        ) : null}
      </div>
      <div className={classNames(styles.block, styles.cloud)}>
        <p>
          <span>{cloud.value}</span>
          {cloud.params?.brackets ? <b>{cloud.params.brackets}</b> : null}
        </p>
        {cloud.params?.icons?.length ? (
          <div>
            {cloud.params.icons.map((icon, idx) => {
              const Icon = iconsMap[icon];
              return <Icon key={idx} />;
            })}
          </div>
        ) : null}
      </div>
    </li>
  );
};

export const Difference = () => {
  const {
    allDiffDataJson: { edges: diffData },
  } = useStaticQuery(graphql`
    query diffData {
      allDiffDataJson {
        edges {
          node {
            rowName
            oss {
              value
              params {
                brackets
                icons
              }
            }
            cloud {
              value
              params {
                brackets
                icons
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'offset-lg-4 col-lg-8 col-12'}>
            <div className={styles.logoGroup}>
              <Oss />
              <Arrow className={styles.arrow} />
              <ArrowFlat className={styles.arrowFlat} />
              <Cloud />
            </div>
          </div>
          <div className={'col-12'}>
            <ul className={styles.table}>{diffData.map(DiffCard)}</ul>
          </div>
        </div>
      </div>

      <Lines imageNumber={3} className={styles.linesWrapperLeft} />
      <Lines imageNumber={2} className={styles.linesWrapperRight} />
    </section>
  );
};
